import { apolloClient } from "@/apollo";
import gql from "graphql-tag";
import store from "@/store";
import router from "@/router";
import { initializeApp } from 'firebase/app';
import {
    getAuth,
    signInWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail,
    createUserWithEmailAndPassword
} from 'firebase/auth'

import { firebaseConfig } from '@/firebase'
import services from ".";

const auth = getAuth()

const createUser = async(email, password, data) => {
    let errors = null
    let uuid = null

    var createUserApp = initializeApp(firebaseConfig, "createUserApp");
    var detachedAuth = getAuth(createUserApp);

    await createUserWithEmailAndPassword(detachedAuth, email, password)
        .then(async(userCredential) => {
            uuid = userCredential.user.uid;

            if (data.user_role.id == 1) {
                data.user_role = "broker"
            }
            if (data.user_role.id == 2) {
                data.user_role = "real_estate"
            }
            if (data.user_role.id == 3) {
                data.user_role = "manager"
            }
            if (data.status.id == 1) {
                data.status = "active"
            }
            if (data.status.id == 2) {
                data.status = "inative"
            }

            let {
                user_email,
                user_first_name,
                user_last_name,
                user_cpf_cnpj,
                user_date_birth,
                user_image,
                user_rz,
                codcolcfo,
                codcfo,
            } = data;

            let status = data.status.toString()
            let user_role = data.user_role.toString()
            let user_fullname = data.user_first_name + ' ' + data.user_last_name
            const user_firebase_uid = uuid;

            if (user_date_birth == "" || user_date_birth == null) {
                user_date_birth = null;
            } else {
                user_date_birth = user_date_birth.split("/");
                user_date_birth = user_date_birth[2] + "-" + user_date_birth[1] + "-" + user_date_birth[0];
            }

            await apolloClient.mutate({
                    mutation: gql `
                  mutation insertUser (
                    $user_first_name : String!, 
                    $user_last_name : String!,
                    $user_rz : String,
                    $user_cpf_cnpj: String, 
                    $user_email: String!, 
                    $user_date_birth: date, 
                    $user_role: String!, 
                    $status: String!, 
                    $codcfo: String, 
                    $codcolcfo: String,
                    $user_firebase_uid : String!,
                    $user_image : String,
                    $user_fullname : String,) {
                    insert_user(objects: {
                      user_rz : $user_rz,
                      user_first_name: $user_first_name,
                      user_last_name: $user_last_name, 
                      user_cpf_cnpj: $user_cpf_cnpj, 
                      user_email: $user_email, 
                      user_date_birth: $user_date_birth, 
                      user_role: $user_role, 
                      status: $status, 
                      codcfo: $codcfo, 
                      codcolcfo: $codcolcfo,
                      user_firebase_uid : $user_firebase_uid,
                      user_image : $user_image,
                      user_fullname : $user_fullname
                    }) {
                      affected_rows
                    }
                  }
                  `,

                    variables: {
                        user_email,
                        user_first_name,
                        user_last_name,
                        user_fullname,
                        user_role,
                        user_cpf_cnpj,
                        user_date_birth,
                        user_image,
                        user_rz,
                        user_firebase_uid,
                        codcolcfo,
                        codcfo,
                        status,
                    }
                })
                .then(() => {
                    var http = new XMLHttpRequest();
                    var url = 'https://us-central1-portais-saobento.cloudfunctions.net/permission/change_permission';
                    auth.currentUser.getIdToken().then((token) => {

                        http.open('POST', url, true);
                        http.setRequestHeader('Content-type', 'application/json');
                        http.onreadystatechange = function() {
                            if (http.readyState == 4 && http.status == 200) {
                                // http.responseText
                            }
                        }
                        http.setRequestHeader("Authorization", 'Bearer ' + token);
                        http.send(JSON.stringify({
                            "email": user_email,
                            "allowed_roles": [user_role]
                        }));
                    });

                })
                .catch(error => {
                    errors = error.message
                })

        })
        .catch(error => {
            errors = error.message
        })

    return { errors }
}



const login = async(user, password) => {
    let errors = null;
    let uuidUser = null;

    await signInWithEmailAndPassword(auth, user, password)
        .then((userCredential) => {
            uuidUser = userCredential.user.uid;
        })
        .catch(error => {
            errors = error.message;
        })

    if (!errors) {
        errors = await fetchUser(uuidUser)
    }

    return { errors }
}

const fetchUser = async(uuidUser) => {
    await apolloClient.query({
            query: gql `
            query getUser($user_firebase_uid: String!) {
              user(
                where: {
                  user_firebase_uid: { _eq: $user_firebase_uid }
                }
              ) {
                uuid_user
                user_first_name
                user_last_name
                user_fullname
                user_image
                user_role
                user_email
                codcolcfo
                codcfo
                status
              }
            }
          `,
            variables: {
                user_firebase_uid: uuidUser,
            },
            fetchPolicy: 'no-cache',
        })
        .then(async(v) => {

            if (v.data["user"][0].status === "inative") {
                await logout()
            } else {
                services.dashboard.getNotifications(v.data["user"][0].user_role, v.data["user"][0].uuid_user);
                store.commit("user/setLoggedUser", v.data["user"][0]);
                if (store.state.user.loggedUser.user_role == 'administrator') {
                    localStorage.dRoute = 'dashboard-admin'
                    if (router.currentRoute.path !== '/dashboard-admin') {
                        router.push("dashboard-admin")
                    }
                }
                if (store.state.user.loggedUser.user_role == 'manager') {
                    localStorage.dRoute = 'dashboard-manager'
                    if (router.currentRoute.path !== '/dashboard-manager') {
                        router.push("dashboard-manager")
                    }
                }
                if (store.state.user.loggedUser.user_role == 'broker') {
                    localStorage.dRoute = 'dashboard'
                    if (router.currentRoute.path !== '/dashboard') {
                        router.push("dashboard")
                    }
                }
                if (store.state.user.loggedUser.user_role == 'real_estate') {
                    localStorage.dRoute = 'dashboard-real-estate'
                    if (router.currentRoute.path !== '/dashboard-real-estate') {
                        router.push("dashboard-real-estate")
                    }
                }
            }
        })
}

const sendResetEmail = async(email) => {
    let errors = null

    await sendPasswordResetEmail(auth, email)
        .then(() => {
            errors = null
        })
        .catch(error => {
            errors = error.message
        })

    return { errors }
}


const logout = async() => {
    await signOut(auth)
        .then(() => {
            store.commit('user/setLoggedUser', null);
        })

    store.commit("user/setLoggedUser", null);
    store.commit("vendas/setComissoes", null);
    store.commit("dashboard/setNotifications", []);
    localStorage.dRoute = 'login'
    router.push({ name: "login" });
};

export default () => ({
    login,
    logout,
    fetchUser,
    sendResetEmail,
    createUser
})