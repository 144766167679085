export default {
    namespaced: true,
    state: {
        notifications: {},
        notificationsCount: 0,
    },
    getters: {},
    mutations: {
        setNotifications(state, val) {
            state.notifications = val;
        },

        setNotificationsCount(state, val) {
            state.notificationsCount = val;
        }
    },
    actions: {}
}