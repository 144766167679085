import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import { apolloProvider } from '@/apollo'
import { getUserState } from '@/firebase'
import services from '@/services'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

/* FontAwesome */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons';
// import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

library.add(fas, far);
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

// garante que o firebase inicialize antes do carregamento do app

new Vue({
    router,
    apolloProvider,
    store,
    render: h => h(App),
    async created() {
        const userState = await getUserState()
        if (userState) {
            await services.auth.fetchUser(
                userState.uid,
            )
        }
        else {
            await services.auth.logout()
        }
    }
}).$mount('#app')

localStorage.dRoute = 'login'
