export default [
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/pdc/broker-real_estate/dashboard/dashboard.vue'),
      meta : {
        requiresAuth : true,
        resource: ['broker'],
        action: 'read',
    
      }
    },
    {
      path: '/dashboard-real-estate',
      name: 'dashboard-real-estate',
      component: () => import('@/views/pdc/broker-real_estate/dashboardRealEstate/dashboard.vue'),
      meta: {
        requiresAuth: true,
        resource: ['real_estate'],
        action: 'read',

      }
    },
    {
      path: '/contracts',
      name: 'contracts',
      component: () => import('@/views/pdc/broker-real_estate/contracts/contracts.vue'),
      meta : {
        requiresAuth : true,
        resource: ['broker', 'real_estate', 'manager'],
        action: 'read',
    
      }
    },
    {
      path: '/revenues',
      name: 'revenues',
      component: () => import('@/views/pdc/broker-real_estate/revenues/revenues.vue'),
      meta : {
        requiresAuth : true,
        resource: ['broker', 'real_estate', 'manager'],
        action: 'read',
      }
    },
    {
      
      path: '/cancellations',
      name: 'cancellations',
      component: () => import('@/views/pdc/broker-real_estate/cancellations/cancellations.vue'),
      meta : {
        requiresAuth : true,
        resource: ['broker', 'real_estate', 'manager'],
        action: 'read',
    
      }
    },
    {
      path: '/dashboard-admin',
      name: 'dashboard-admin',
      component: () => import('@/views/pdc/administrator/dashboard/dashboard-admin.vue'),
      meta : {
        requiresAuth : true,
        resource: ['administrator'],
        action: 'read',
    
      }
    },
    {
      path: '/dashboard-manager',
      name: 'dashboard-manager',
      component: () => import('@/views/pdc/manager/dashboard/dashboard-manager.vue'),
      meta : {
        requiresAuth : true,
        resource: ['manager'],
        action: 'read',
    
      }
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: () => import('@/views/pdc/manager/calendar/calendar.vue'),
      meta : {
        requiresAuth : true,
        action : 'read',
        resource: ['broker', 'manager','administrator'],
      }
    },
    {
      path: '/cities',
      name: 'cities',
      component: () => import('@/views/pdc/manager/cities/cities.vue'),
      meta : {
        requiresAuth : true,
        action : 'read',
        resource: ['manager', 'administrator'],
      }
    },
    {
      path: '/locations',
      name: 'locations',
      component: () => import('@/views/pdc/manager/locations/locations.vue'),
      meta : {
        requiresAuth : true,
        action : 'read',
        resource: ['manager', 'administrator'],
      }
    },

    {
      path: '/categories',
      name: 'categories',
      component: () => import('@/views/pdc/administrator/categories/categories.vue'),
      meta : {
        requiresAuth : true,
        action : 'read',
        resource: ['manager', 'administrator'],
      }
    },
    
    {
      path: '/communicateds',
      name: 'communicateds',
      component: () => import('@/views/pdc/manager/communicateds/communicateds.vue'),
      meta : {
        requiresAuth : true,
        action : 'read',
        resource: ['manager', 'administrator'],
      }
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/pdc/administrator/users/users.vue'),
      meta : {
        requiresAuth : true,
        action : 'read',
        resource : ['administrator'],
      }
    },
  ]
  