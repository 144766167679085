export default {
    namespaced: true,
    state: {
        comissoes: {},
    },
    getters: {
        // criar apenas quando necessário retornar ordenado, filtrado, etc
    },
    mutations: {
        setComissoes(state, val) {
            state.comissoes = val
        },
    },
    actions: {},
}