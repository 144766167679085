import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import user from './user'
import vendas from './vendas'
import dashboard from './dashboard'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        user,
        vendas,
        dashboard
    },
    strict: process.env.DEV,
    plugins: [createPersistedState()]
})