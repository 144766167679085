import Vue from 'vue'
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { split } from 'apollo-link';
import VueApollo from 'vue-apollo';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { setContext } from 'apollo-link-context';
import { getUserState } from '@/firebase'

const httpLink = new HttpLink({ uri: 'https://hasura.saobento.com.br/v1/graphql' });

export const getAuth = async() => {
    const userState = await getUserState()
    if (userState) {
        return 'Bearer ' + userState.accessToken
    } else {
        return ''
    }
}

const middlewareLink = setContext(async() => {
    return {
        headers: {
            Authorization: `${await getAuth()}`,
        },
    }
})

const nlink = middlewareLink.concat(httpLink);

const wsLink = new WebSocketLink({ uri: 'wss://hasura.saobento.com.br/v1/graphql' });

const middlewareWsLink = setContext(async() => {
    return {
        options: {
            reconnect: true,
            connectionParams: {
                headers: {
                    Authorization: `${await getAuth()}`
                }
            }
        }
    }
})

const nWsLink = middlewareWsLink.concat(wsLink)

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
    ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
    },
    nWsLink,
    nlink,
);

export const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache({
        addTypename: false
    })
});

Vue.use(VueApollo);

export const apolloProvider = new VueApollo({
    defaultClient: apolloClient
});
/* End Apollo */