export default {
  namespaced: true,
  state: {
      loggedUser: {},
  },
  getters: {
      // criar apenas quando necessário retornar ordenado, filtrado, etc
  },
  mutations: {
      setLoggedUser(state, val) {
          state.loggedUser = val
      },
  },
  actions: {},
}