import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import "firebase/auth";
import "firebase/analytics";

export const getUserState = () =>
    new Promise((resolve, reject) =>
        onAuthStateChanged(getAuth(), resolve, reject)
    )

export const firebaseConfig = {
    apiKey: "AIzaSyBK--Ecdpq3Mb4bwHe7nZDh-INm4WqGqCI",
    authDomain: "portais-saobento.firebaseapp.com",
    projectId: "portais-saobento",
    storageBucket: "portais-saobento.appspot.com",
    messagingSenderId: "837669273070",
    appId: "1:837669273070:web:79404f2b894f699103a2ff",
    measurementId: "G-3WBYPJ29PC"
};

export const firebaseApp = initializeApp(firebaseConfig)