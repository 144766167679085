import {
    apolloClient
} from "@/apollo";
import store from "@/store";
import gql from "graphql-tag";

const getComissoes = async(codcfo) => {
    apolloClient
        .query({
            query: gql `
                query getComissoes($codcfo: String) {
                api_view_portal_corretor_ind_comissoes(
                    where: { corretor: { _eq: $codcfo } }
                ) {
                    valor_comissao_prevista
                }
                }
            `,
            variables: {
                codcfo: codcfo,
            },
            fetchPolicy: "cache-first", //'cache-first ou no-cache'
        })
        .then((data) => {
            if (data.data.api_view_portal_corretor_ind_comissoes.length > 0) {
                store.commit(
                    "vendas/setComissoes",
                    data["data"]["api_view_portal_corretor_ind_comissoes"][0][
                        "valor_comissao_prevista"
                    ]
                );
            }
        });
};

const getNotifications = async(user_role, myuUid) => {
    let GET_COMMUNICATEDS
    if (user_role == 'broker') {
        GET_COMMUNICATEDS = gql `
        query getCommunicateds {
            communicateds(order_by: {updated_at: desc}
            where: { broker_view: { _eq: true } } 
            ) {
            uuid_communicated
            title
            description
            real_state_view
            broker_view
            created_at
            expires_at
            updated_at
            status
            user {
                user_image
                user_fullname
                user_role
            }
            category {
                description
                color
            }
            communicateds_views {
                user_uuid
                created_at
            }
            }
        }  
        `;
    } else if (user_role == 'real_estate') {
        GET_COMMUNICATEDS = gql `
        query getCommunicateds {
            communicateds(order_by: {updated_at: desc}
            where: { real_state_view: { _eq: true } } 
            ) {
            uuid_communicated
            title
            description
            real_state_view
            broker_view
            created_at
            expires_at
            updated_at
            status
            user {
                user_image
                user_fullname
                user_role
            }
            category {
                description
                color
            }
            communicateds_views {
                user_uuid
                created_at
            }
            }
        }  
        `;
    } else {
        GET_COMMUNICATEDS = gql `
        query getCommunicateds {
            communicateds(order_by: {updated_at: desc}
            ) {
            uuid_communicated
            title
            description
            real_state_view
            broker_view
            created_at
            expires_at
            updated_at
            status
            user {
                user_image
                user_fullname
                user_role
            }
            category {
                description
                color
            }
            communicateds_views {
                user_uuid
                created_at
            }            
            }
        }  
        `;
    }

    apolloClient.query({
            query: GET_COMMUNICATEDS,
            fetchPolicy: "no-cache", //'cache-first ou no-cache'
        })
        .then((data) => {
            let communicateds = data["data"]["communicateds"]
            if (user_role == 'broker') {
                const result = communicateds.filter(item => item.broker_view == true)
                store.commit("dashboard/setNotifications", result);
            } else if (user_role == 'real_estate') {
                const result = communicateds.filter(item => item.real_state_view == true)
                store.commit("dashboard/setNotifications", result);
            } else {
                store.commit("dashboard/setNotifications", communicateds);
            }

            let count = 0;
            for (let i = 0; i < communicateds.length; i++) {
                for (let j = 0; j < communicateds[i].communicateds_views.length; j++) {
                    if (communicateds[i].communicateds_views[j].user_uuid == myuUid) {
                        count++;
                    }
                }
            }

            store.commit("dashboard/setNotificationsCount", communicateds.length - count);
        })
};

export default () => ({
    getComissoes,
    getNotifications
});