import Vue from 'vue'
import VueRouter from 'vue-router'
import pdc from './pdc'
import { getUserState } from '../firebase'
import store from '@/store'
var defaultDashboard = 'login';

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        { path: '/', redirect: { name: defaultDashboard } },

        ...pdc,
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/login/login.vue'),
            meta: {
                layout: 'full',
            },
        },

        {
        path: '/forgot',
            name: 'forgot',
            component: () => import('@/views/forgot/forgot.vue'),
            meta: {
                layout: 'full',
            },
        },

        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})


router.beforeEach(async (to, from, next) => {

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const requiresUnauth = to.matched.some(record => record.meta.requiresUnauth)

    const isAuth = await getUserState()

    if (requiresAuth && !isAuth) next('/login')
    else if (requiresUnauth && isAuth) {
        const permission = store.state.user.loggedUser.user_role
        if (permission == '') { defaultDashboard = 'login' }
        if (permission == 'administrator') { defaultDashboard = 'dashboard-admin' }
        if (permission == 'manager') { defaultDashboard = 'dashboard-manager' }
        if (permission == 'broker') { defaultDashboard = 'dashboard' }
        if (permission == 'real_estate') { defaultDashboard = 'dashboard-real-estate' }
        next('/')
    }
    else next()

});

// splash screen
router.afterEach(() => {
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router